<script lang="ts">
  import {
    accountsPerSubscription,
    getAvailableOptions,
    getItemProductId,
    getProductPrice,
    serverNames,
    type AccountType,
    type Level,
    type MMRType,
    type Region
  } from "@/games/lol/account_info";
  import RadioChecked from "@/images/radio-checked.svg";
  import RadioUnchecked from "@/images/radio-unchecked.svg";
  import ReviewsIO from "@/images/reviews_logo.svg";
  import { onMount } from "svelte";
  import StarFilled from "lucide-svelte/icons/star";

  import AccountsBadges from "@/components/accounts/badges.svelte";
  import SkinSearchDropdown from "@/components/accounts/skins/SkinSearchDropdown.svelte";
  import DiscountCode from "@/components/checkout/discount-code.svelte";
  import AccountIcon from "@/components/icons/account-icon.svelte";
  import Button from "@/components/ui/button/button.svelte";
  import Image from "@/components/ui/image/image.svelte";
  import Typography from "@/components/ui/typography/typography.svelte";
  import { client } from "@/helpers/trpc/trpc-client";
  import { typedEntries } from "@/helpers/typed-entries";
  import { addCartItem, isCartOpen } from "@/stores/cart";
  import { discountPercentage } from "@/stores/discounts";

  export let region: Region = "euw";
  export let level: Level = "30";

  let selectedRegion: Region = region;
  let selectedLevel: Level | null = level;
  let selectedType: AccountType = "standard";
  let selectedMMR: MMRType = "fresh";
  let isSubscription = false;
  let skins: string[] = [];
  let stock: Record<string, number> = {};
  let selectedSkin: string | null = null;
  let skinEnabled = false;
  let availableOptions: ReturnType<typeof getAvailableOptions>;
  let selectedProductId: string | null = null;
  let selectedProductPrice: ReturnType<typeof getProductPrice>;
  let levelsColors = {
    30: "#BEBEBE",
    40: "#2B6BE9",
    45: "#E41E3F",
    60: "#04B856"
  };

  //update product when any of the selected options change
  $: {
    updateSelectedProduct();
    selectedRegion;
    selectedLevel;
    selectedType;
    selectedMMR;
    selectedSkin;
    $discountPercentage;
    isSubscription;
  }

  //reset MMR when type changes
  $: selectedType && resetMMR();
  const resetMMR = () => {
    selectedMMR = availableOptions.mmrTypes[0]!;
    updateSelectedProduct();
  };

  $: checkoutDisabled =
    !selectedProductId ||
    !stock[selectedProductId] ||
    (!!selectedSkin && !skinEnabled);

  const updateSelectedProduct = () => {
    selectedProductId = getItemProductId(
      selectedRegion,
      selectedLevel,
      selectedType,
      selectedMMR
    );
    availableOptions = getAvailableOptions(
      selectedRegion,
      selectedType,
      selectedMMR
    );
    selectedProductPrice = getProductPrice(
      selectedProductId || "",
      selectedSkin,
      isSubscription,
      $discountPercentage
    );
  };

  onMount(async () => {
    [skins, stock] = await Promise.all([
      client.accounts.skinNames.query(),
      client.accounts.accountStock.query()
    ]);
    selectedRegion = region;
    selectedLevel = level;
  });

  const addSelectedItemToCart = () => {
    if (!selectedProductId || !selectedLevel) return;

    $isCartOpen = true;
    addCartItem({
      type: "account",
      data: {
        productId: selectedProductId,
        selected_skin: selectedSkin as string
      },
      subscription: isSubscription,
      quantity: 1,
      original_price: selectedProductPrice.originalPrice,
      price: selectedProductPrice.price / 100,
      currency: selectedProductPrice.currencyCode
    });
  };
</script>

<div class="md:container">
  <div class="mb-7 flex flex-wrap gap-3 px-6 md:px-0">
    {#each typedEntries(serverNames) as [server, name] (server)}
      <div>
        <input
          name="server"
          type="radio"
          id={server}
          value={server}
          class="peer hidden"
          bind:group={selectedRegion} />
        <label
          for={server}
          aria-checked={selectedRegion === server}
          class="relative flex w-fit cursor-pointer flex-col items-start justify-start rounded-md border-2 border-dark-gray px-4 py-2.5 transition-all hover:bg-light-black aria-checked:border-accent">
          <Typography
            color={selectedRegion === server ? "default" : "muted"}
            variant="large">{name}</Typography>
        </label>
      </div>
    {/each}
  </div>
  <div class="flex flex-col gap-6 md:flex-row">
    <div
      class="grid h-fit gap-4 border-y border-dark-gray bg-theme-black p-6 md:container md:mb-20 md:w-1/2 md:rounded-2xl md:border lg:w-3/5">
      <Typography variant="h4">Choose Your Package</Typography>
      <div class="flex flex-wrap gap-3 px-6 md:px-0">
        <div>
          <input
            name="account_type"
            type="radio"
            id="standard"
            value="standard"
            class="peer hidden"
            bind:group={selectedType} />
          <label
            for="standard"
            aria-checked={selectedType === "standard"}
            class="relative flex w-fit cursor-pointer flex-col items-start justify-start rounded-md border-2 border-dark-gray px-4 py-2.5 transition-all hover:bg-light-black aria-checked:border-accent">
            <Typography
              color={selectedType === "standard" ? "default" : "muted"}
              variant="large">Standard (Undetected)</Typography>
          </label>
        </div>

        <div>
          <input
            name="account_type"
            type="radio"
            id="hand"
            value="hand"
            class="peer hidden"
            bind:group={selectedType} />
          <label
            for="hand"
            aria-checked={selectedType === "hand"}
            class="relative flex w-fit cursor-pointer flex-col items-start justify-start rounded-md border-2 border-dark-gray px-4 py-2.5 transition-all hover:bg-light-black aria-checked:border-accent">
            <Typography
              color={selectedType === "hand" ? "default" : "muted"}
              variant="large">Handmade</Typography>
          </label>
        </div>
      </div>
      <!-- <div class="mb-7 flex flex-wrap gap-3 px-6 md:px-0">
        {#if availableOptions.mmrTypes.includes("fresh")}
          <div>
            <input
              name="selectedMMR"
              type="radio"
              id="fresh"
              value="fresh"
              class="peer hidden"
              bind:group={selectedMMR} />
            <label
              for="fresh"
              class="flex cursor-pointer gap-2 rounded-2xl border-2 border-dark-gray p-3 hover:bg-light-black peer-checked:border-accent"
              >Fresh MMR
            </label>
          </div>
        {/if}
        {#if availableOptions.mmrTypes.includes("played")}
          <div>
            <input
              name="selectedMMR"
              type="radio"
              id="played"
              value="played"
              class="peer hidden"
              bind:group={selectedMMR} />
            <label
              for="played"
              class="flex cursor-pointer gap-2 rounded-2xl border-2 border-dark-gray p-3 hover:bg-light-black peer-checked:border-accent">
              10 Normal Games Played
            </label>
          </div>
        {/if}
      </div> -->
      <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
        {#each availableOptions.levels ?? [] as level}
          {@const productId = getItemProductId(
            selectedRegion,
            level,
            selectedType,
            selectedMMR
          )}
          {@const levelStock = productId ? stock[productId] : null}
          {@const price = productId
            ? getProductPrice(
                productId,
                null,
                isSubscription,
                $discountPercentage
              )
            : null}
          <div>
            <input
              name="level"
              type="radio"
              id={level}
              value={level}
              class="peer hidden"
              bind:group={selectedLevel}
              disabled={!levelStock} />
            <label
              for={level}
              aria-checked={selectedLevel === level}
              class="border-dark-g relative flex min-h-full w-full cursor-pointer flex-col items-start justify-start rounded-xl border p-6 transition-all hover:bg-light-black peer-disabled:opacity-75 peer-disabled:hover:cursor-not-allowed aria-checked:border-accent">
              <div class="flex w-full items-center gap-3">
                <div
                  class="flex h-[56px] w-[56px] items-center justify-center rounded-2xl border border-dark-gray bg-light-black">
                  <AccountIcon
                    class="h-8 w-8"
                    color={level ? levelsColors[level] : "#BEBEBE"} />
                </div>
                <div
                  class=" flex h-fit items-center justify-center rounded-[40px] border border-dark-gray bg-light-black px-4 py-2">
                  <Typography variant="large">
                    {#if isSubscription}
                      Up to {accountsPerSubscription} Level {level} Accounts
                    {:else}
                      Level {level}
                    {/if}
                  </Typography>
                </div>
              </div>

              <div
                class="my-5 flex w-full flex-wrap items-center justify-between px-[1px]">
                {#if price}
                  <Typography variant="lead">
                    {price.priceDisplay}
                    {#if isSubscription}<span class=" text-sm text-zinc-500"
                        >/Month</span
                      >{/if}</Typography>
                  <Typography variant="large" color="muted" class="line-through"
                    >{`${price.currencyCode}${
                      price.originalPrice / 100
                    }`}</Typography>
                {/if}
              </div>

              <AccountsBadges {isSubscription} {level} />

              <p
                class={`text-red-700 ${
                  levelStock && levelStock > 5 ? "hidden" : "mt-5"
                }`}>
                {!levelStock ? "Sold out!" : `Only ${levelStock} left!`}
              </p>
            </label>
          </div>
        {/each}
      </div>
    </div>
    <div
      class="mb-5 grid h-fit gap-4 border-y border-dark-gray bg-theme-black md:mb-20 md:w-1/2 md:rounded-2xl md:border lg:w-2/5">
      <div
        class="flex items-center justify-center border-b border-dark-gray bg-light-black p-6 md:rounded-t-2xl">
        <Typography class="flex items-center gap-2">
          <AccountIcon
            class="h-8 w-8"
            color={selectedLevel ? levelsColors[selectedLevel] : "#BEBEBE"} />
          {selectedRegion.toUpperCase()} - LVL {selectedLevel || 0}
        </Typography>
      </div>
      <div
        class="flex flex-col flex-wrap items-start justify-between gap-3 px-6">
        {#if selectedProductId}
          <Typography variant="large" class="align-left"
            >Pick a skin shard with your account (Optional)</Typography>
          <div class="z-10 w-full">
            <SkinSearchDropdown
              {skins}
              productId={selectedProductId}
              bind:selectedOption={selectedSkin}
              bind:skinEligible={skinEnabled} />
          </div>
        {/if}
        <div
          class="my-3 flex w-full flex-nowrap items-center justify-center gap-2 bg-light-black">
          <div class="flex items-center gap-2 rounded-[56px] px-6 py-4">
            <div class="flex items-center">
              <Typography>4.87</Typography>
              <StarFilled class="ml-1 h-4 w-4 fill-white" />
            </div>
            <Image src={ReviewsIO} class="h-4 w-24" />
          </div>
        </div>
      </div>
      <DiscountCode />
      <div
        class="flex flex-col flex-wrap justify-between gap-6 px-6 md:flex-nowrap">
        <div class="flex items-center justify-between gap-2">
          <Typography variant="lead" color="default">Total Price:</Typography>
          {#if !checkoutDisabled}
            <Typography variant="h3" as="span" color="success">
              {#if $discountPercentage != 0}
                <Typography
                  as="span"
                  variant="h3"
                  color="destructive"
                  class="line-through">
                  {selectedProductPrice.currencyCode}{(
                    selectedProductPrice.price /
                    (1 - $discountPercentage) /
                    100
                  ).toFixed(2)}
                </Typography>
              {/if}
              {selectedProductPrice.priceDisplay}
            </Typography>
          {:else}
            <Typography variant="lead" as="span" color="destructive">
              Please select a valid option
            </Typography>
          {/if}
        </div>
        <Button
          variant="accent"
          disabled={checkoutDisabled}
          on:click={addSelectedItemToCart}
          data-tooltip-target="checkout-disabled-tooltip"
          class="mb-6 w-full disabled:hover:cursor-not-allowed">
          {#if checkoutDisabled}
            Please select a valid option
          {:else}
            Add to Cart
          {/if}
        </Button>
      </div>
    </div>
  </div>
</div>
