<script lang="ts">
  import Repeat from "lucide-svelte/icons/repeat";

  import { Image } from "@/components/ui/image";
  import { Typography } from "@/components/ui/typography";
  import {
    accountsPerSubscription,
    levelsDetails,
    type Level
  } from "@/games/lol/account_info";
  import { cn } from "@/helpers/tw-utils";

  import CheckIcon from "@/images/check.svg";

  let className: string = "";

  export { className as class };

  export let level: Level = "30";
  export let isSubscription = false;
  export let variant: "p" = "p";

  let levelDetails = levelsDetails[level];

  export let itemClass = "";
  let itemBaseClass = "flex gap-2 items-center";
</script>

<div class={cn("grid gap-4", className)}>
  <div class={cn(itemBaseClass, itemClass)}>
    <Image src={CheckIcon} alt="Blue Essence" class="h-4 w-4" />
    <Typography weight="medium" {variant}>
      {levelDetails?.estimated}
      <Typography weight="medium" {variant} as="span" color="muted">
        Blue Essence {levelDetails?.pure}
        pure - {levelDetails?.loot} loot (Estimated)
      </Typography>
    </Typography>
  </div>
  <div class={cn(itemBaseClass, itemClass)}>
    <Image src={CheckIcon} alt="Honor level" class="h-4 w-4" />
    <Typography weight="medium" {variant}>
      Level 2
      <Typography weight="medium" {variant} as="span" color="muted">
        Honor
      </Typography>
    </Typography>
  </div>
  <div class={cn(itemBaseClass, itemClass)}>
    <Image src={CheckIcon} alt="Lifetime Warranty" class="h-4 w-4" />
    <Typography {variant} color="muted" class="!text-green-500">
      Undetected & Lifetime Warranty
    </Typography>
  </div>
  <div class={cn(itemBaseClass, itemClass)}>
    <Image src={CheckIcon} alt="Email Access" class="h-4 w-4" />
    <Typography weight="medium" {variant} color="muted">
      Full email access
    </Typography>
  </div>
  <div class={cn(itemBaseClass, itemClass)}>
    <Image src={CheckIcon} alt="Instant Delivery" class="h-4 w-4" />
    <Typography weight="medium" {variant} color="muted">
      Instant delivery
    </Typography>
  </div>
  {#if isSubscription}
    <div class={cn(itemBaseClass, itemClass)}>
      <Repeat class="h-8 w-8 text-slate-200" />
      <Typography weight="medium" {variant} color="muted">
        Up to
        <Typography weight="medium" {variant} as="span">
          {accountsPerSubscription}
        </Typography>
        Accounts (every
        <Typography weight="medium" {variant} as="span">
          {30 / accountsPerSubscription}
        </Typography>
        days)
      </Typography>
    </div>
  {/if}
</div>
